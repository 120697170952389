import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Map, TileLayer, LayersControl } from "react-leaflet";
import AutocompleteAsync from "./../components/AutoCompleteAsync";
import HeatmapLayer from "react-leaflet-heatmap-layer";
import Choropleth from "react-leaflet-choropleth";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const gradient = [
  "#F2EDA4",
  "#F2ECA3",
  "#F2ECA3",
  "#F2EBA3",
  "#F2EBA2",
  "#F2EAA2",
  "#F2EAA2",
  "#F2E9A1",
  "#F2E9A1",
  "#F2E8A1",
  "#F2E8A0",
  "#F2E7A0",
  "#F2E7A0",
  "#F2E69F",
  "#F2E69F",
  "#F2E59F",
  "#F2E59E",
  "#F2E49E",
  "#F2E49E",
  "#F2E39D",
  "#F2E39D",
  "#F2E39D",
  "#F2E29C",
  "#F2E29C",
  "#F2E19C",
  "#F2E19B",
  "#F2E09B",
  "#F2E09B",
  "#F2DF9A",
  "#F2DF9A",
  "#F2DE9A",
  "#F2DE99",
  "#F2DD99",
  "#F2DD99",
  "#F2DC98",
  "#F2DC98",
  "#F2DB98",
  "#F2DB97",
  "#F2DA97",
  "#F3DA97",
  "#F3DA96",
  "#F3D996",
  "#F3D996",
  "#F3D895",
  "#F3D895",
  "#F3D795",
  "#F3D794",
  "#F3D694",
  "#F3D694",
  "#F3D593",
  "#F3D593",
  "#F3D493",
  "#F3D492",
  "#F3D392",
  "#F3D392",
  "#F3D291",
  "#F3D291",
  "#F3D191",
  "#F3D190",
  "#F3D090",
  "#F3D090",
  "#F3D08F",
  "#F3CF8F",
  "#F3CF8F",
  "#F3CE8E",
  "#F3CE8E",
  "#F3CD8E",
  "#F3CD8D",
  "#F3CC8D",
  "#F3CC8D",
  "#F3CB8C",
  "#F3CB8C",
  "#F3CA8C",
  "#F3CA8C",
  "#F3C98B",
  "#F3C98B",
  "#F3C88B",
  "#F4C88A",
  "#F4C78A",
  "#F4C78A",
  "#F4C789",
  "#F4C689",
  "#F4C689",
  "#F4C588",
  "#F4C588",
  "#F4C488",
  "#F4C487",
  "#F4C387",
  "#F4C387",
  "#F4C286",
  "#F4C286",
  "#F4C186",
  "#F4C185",
  "#F4C085",
  "#F4C085",
  "#F4BF84",
  "#F4BF84",
  "#F4BE84",
  "#F4BE83",
  "#F4BD83",
  "#F4BD83",
  "#F4BD82",
  "#F4BC82",
  "#F4BC82",
  "#F4BB81",
  "#F4BB81",
  "#F4BA81",
  "#F4BA80",
  "#F4B980",
  "#F4B980",
  "#F4B87F",
  "#F4B87F",
  "#F4B77F",
  "#F4B77E",
  "#F4B67E",
  "#F4B67E",
  "#F5B57D",
  "#F5B57D",
  "#F5B47D",
  "#F5B47C",
  "#F5B47C",
  "#F5B37C",
  "#F5B37B",
  "#F5B27B",
  "#F5B27B",
  "#F5B17A",
  "#F5B17A",
  "#F5B07A",
  "#F5B079",
  "#F5AF79",
  "#F5AF79",
  "#F5AE78",
  "#F5AE78",
  "#F5AD78",
  "#F5AD77",
  "#F5AC77",
  "#F5AC77",
  "#F5AB76",
  "#F5AB76",
  "#F5AA76",
  "#F5AA75",
  "#F5AA75",
  "#F5A975",
  "#F5A975",
  "#F5A874",
  "#F5A874",
  "#F5A774",
  "#F5A773",
  "#F5A673",
  "#F5A673",
  "#F5A572",
  "#F5A572",
  "#F5A472",
  "#F5A471",
  "#F6A371",
  "#F6A371",
  "#F6A270",
  "#F6A270",
  "#F6A170",
  "#F6A16F",
  "#F6A16F",
  "#F6A06F",
  "#F6A06E",
  "#F69F6E",
  "#F69F6E",
  "#F69E6D",
  "#F69E6D",
  "#F69D6D",
  "#F69D6C",
  "#F69C6C",
  "#F69C6C",
  "#F69B6B",
  "#F69B6B",
  "#F69A6B",
  "#F69A6A",
  "#F6996A",
  "#F6996A",
  "#F69869",
  "#F69869",
  "#F69769",
  "#F69768",
  "#F69768",
  "#F69668",
  "#F69667",
  "#F69567",
  "#F69567",
  "#F69466",
  "#F69466",
  "#F69366",
  "#F69365",
  "#F69265",
  "#F69265",
  "#F79164",
  "#F79164",
  "#F79064",
  "#F79063",
  "#F78F63",
  "#F78F63",
  "#F78E62",
  "#F78E62",
  "#F78E62",
  "#F78D61",
  "#F78D61",
  "#F78C61",
  "#F78C60",
  "#F78B60",
  "#F78B60",
  "#F78A5F",
  "#F78A5F",
  "#F7895F",
  "#F7895E",
  "#F7885E",
  "#F7885E",
  "#F7875D",
  "#F7875D",
  "#F7865D",
  "#F7865D",
  "#F7855C",
  "#F7855C",
  "#F7845C",
  "#F7845B",
  "#F7845B",
  "#F7835B",
  "#F7835A",
  "#F7825A",
  "#F7825A",
  "#F78159",
  "#F78159",
  "#F78059",
  "#F78058",
  "#F77F58",
  "#F87F58",
  "#F87E57",
  "#F87E57",
  "#F87D57",
  "#F87D56",
  "#F87C56",
  "#F87C56",
  "#F87B55",
  "#F87B55",
  "#F87B55",
  "#F87A54",
  "#F87A54",
  "#F87954",
  "#F87953",
  "#F87853",
  "#F87853",
  "#F87752",
  "#F87752",
  "#F87652",
  "#F87651",
  "#F87551",
  "#F87551",
  "#F87450",
  "#F87450",
  "#F87350",
  "#F8734F",
  "#F8724F",
  "#F8724F",
  "#F8714E",
  "#F8714E",
  "#F8714E",
  "#F8704D",
  "#F8704D",
  "#F86F4D",
  "#F86F4C",
  "#F86E4C",
  "#F86E4C",
  "#F86D4B",
  "#F96D4B",
  "#F96C4B",
  "#F96C4A",
  "#F96B4A",
  "#F96B4A",
  "#F96A49",
  "#F96A49",
  "#F96949",
  "#F96948",
  "#F96848",
  "#F96848",
  "#F96847",
  "#F96747",
  "#F96747",
  "#F96646",
  "#F96646",
  "#F96546",
  "#F96546",
  "#F96445",
  "#F96445",
  "#F96345",
  "#F96344",
  "#F96244",
  "#F96244",
  "#F96143",
  "#F96143",
  "#F96043",
  "#F96042",
  "#F95F42",
  "#F95F42",
  "#F95E41",
  "#F95E41",
  "#F95E41",
  "#F95D40",
  "#F95D40",
  "#F95C40",
  "#F95C3F",
  "#F95B3F",
  "#F95B3F",
  "#FA5A3E",
  "#FA5A3E",
  "#FA593E",
  "#FA593D",
  "#FA583D",
  "#FA583D",
  "#FA573C",
  "#FA573C",
  "#FA563C",
  "#FA563B",
  "#FA553B",
  "#FA553B",
  "#FA553A",
  "#FA543A",
  "#FA543A",
  "#FA5339",
  "#FA5339",
  "#FA5239",
  "#FA5238",
  "#FA5138",
  "#FA5138",
  "#FA5037",
  "#FA5037",
  "#FA4F37",
  "#FA4F36",
  "#FA4E36",
  "#FA4E36",
  "#FA4D35",
  "#FA4D35",
  "#FA4C35",
  "#FA4C34",
  "#FA4B34",
  "#FA4B34",
  "#FA4B33",
  "#FA4A33",
  "#FA4A33",
  "#FA4932",
  "#FA4932",
  "#FB4832",
  "#FB4831",
  "#FB4731",
  "#FB4731",
  "#FB4630",
  "#FB4630",
  "#FB4530",
  "#FB452F",
  "#FB442F",
  "#FB442F",
  "#FB432E",
  "#FB432E",
  "#FB422E",
  "#FB422E",
  "#FB422D",
  "#FB412D",
  "#FB412D",
  "#FB402C",
  "#FB402C",
  "#FB3F2C",
  "#FB3F2B",
  "#FB3E2B",
  "#FB3E2B",
  "#FB3D2A",
  "#FB3D2A",
  "#FB3C2A",
  "#FB3C29",
  "#FB3B29",
  "#FB3B29",
  "#FB3A28",
  "#FB3A28",
  "#FB3928",
  "#FB3927",
  "#FB3827",
  "#FB3827",
  "#FB3826",
  "#FB3726",
  "#FB3726",
  "#FC3625",
  "#FC3625",
  "#FC3525",
  "#FC3524",
  "#FC3424",
  "#FC3424",
  "#FC3323",
  "#FC3323",
  "#FC3223",
  "#FC3222",
  "#FC3122",
  "#FC3122",
  "#FC3021",
  "#FC3021",
  "#FC2F21",
  "#FC2F20",
  "#FC2F20",
  "#FC2E20",
  "#FC2E1F",
  "#FC2D1F",
  "#FC2D1F",
  "#FC2C1E",
  "#FC2C1E",
  "#FC2B1E",
  "#FC2B1D",
  "#FC2A1D",
  "#FC2A1D",
  "#FC291C",
  "#FC291C",
  "#FC281C",
  "#FC281B",
  "#FC271B",
  "#FC271B",
  "#FC261A",
  "#FC261A",
  "#FC251A",
  "#FC2519",
  "#FC2519",
  "#FC2419",
  "#FD2418",
  "#FD2318",
  "#FD2318",
  "#FD2217",
  "#FD2217",
  "#FD2117",
  "#FD2117",
  "#FD2016",
  "#FD2016",
  "#FD1F16",
  "#FD1F15",
  "#FD1E15",
  "#FD1E15",
  "#FD1D14",
  "#FD1D14",
  "#FD1C14",
  "#FD1C13",
  "#FD1C13",
  "#FD1B13",
  "#FD1B12",
  "#FD1A12",
  "#FD1A12",
  "#FD1911",
  "#FD1911",
  "#FD1811",
  "#FD1810",
  "#FD1710",
  "#FD1710",
  "#FD160F",
  "#FD160F",
  "#FD150F",
  "#FD150E",
  "#FD140E",
  "#FD140E",
  "#FD130D",
  "#FD130D",
  "#FD120D",
  "#FD120C",
  "#FE120C",
  "#FE110C",
  "#FE110B",
  "#FE100B",
  "#FE100B",
  "#FE0F0A",
  "#FE0F0A",
  "#FE0E0A",
  "#FE0E09",
  "#FE0D09",
  "#FE0D09",
  "#FE0C08",
  "#FE0C08",
  "#FE0B08",
  "#FE0B07",
  "#FE0A07",
  "#FE0A07",
  "#FE0906",
  "#FE0906",
  "#FE0906",
  "#FE0805",
  "#FE0805",
  "#FE0705",
  "#FE0704",
  "#FE0604",
  "#FE0604",
  "#FE0503",
  "#FE0503",
  "#FE0403",
  "#FE0402",
  "#FE0302",
  "#FE0302",
  "#FE0201",
  "#FE0201",
  "#FE0101",
  "#FE0100",
  "#FE0000",
  "#FE0000",
  "#FF0000",
];

const style = {
  fillColor: "#F28F3B",
  weight: 0.5,
  opacity: 0.5,
  color: "white",
  dashArray: "3",
  fillOpacity: 0.5,
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  toggleText: {
    width: "4em",
    marginLeft: "2em",
  },
  toggleContainer: {
    display: "flex",
    alignSelf: "flex-end",
    marginRight: "3em",
    marginTop: "1em",
  },
}));

// Proxy to local back-end server
function fetchApi(resource, init) {
  return fetch(`${process.env.REACT_APP_ENDPOINT}/${resource}`, init);
}

function AeroScreen() {
  const [starttime, setStarttime] = useState(new Date("2018-12-23T00:00"));
  const [endtime, setEndtime] = useState(new Date("2018-12-23T00:00"));
  const [country, setCountry] = useState([]);
  const [airline, setAirline] = useState([]);
  const [aircraftManufacturer, setAircraftManufacturer] = useState([]);
  const [aircraftType, setAircraftType] = useState([]);
  const [aircraft, setAircraft] = useState([]);
  const [provider, setProvider] = useState([]);
  const [dataRequest, setDataRequest] = React.useState("max");
  const [data, setData] = useState([]);

  const classes = useStyles();
  const [openLoadingBar, setOpenLoadingBar] = React.useState(false);

  async function showOnMap() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        params: {
          dataRequest: dataRequest,
          country: country,
          airline: airline,
          aircraftManufacturer: aircraftManufacturer,
          aircraftType: aircraftType,
          aircraft: aircraft,
          provider: provider,
          starttime: starttime,
          endtime: endtime,
        },
      }),
    };
    setOpenLoadingBar(!openLoadingBar);
    await fetchApi("api/aero/capacity", requestOptions)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((err) => {
        setOpenLoadingBar(false);
      });

    setOpenLoadingBar(false);
  }

  async function exportCsv() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        params: {
          dataRequest: dataRequest,
          country: country,
          airline: airline,
          aircraftManufacturer: aircraftManufacturer,
          aircraftType: aircraftType,
          aircraft: aircraft,
          provider: provider,
          starttime: starttime,
          endtime: endtime,
        },
      }),
    };
    setOpenLoadingBar(!openLoadingBar);
    await fetchApi("api/aero/export", requestOptions)
      .then(function (t) {
        return t.blob().then((b) => {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(b);
          a.setAttribute("download", "export.csv");
          a.click();
        });
      })
      .catch((err) => {
        setOpenLoadingBar(false);
      });
    setOpenLoadingBar(false);
  }

  function get_sum() {
    if (data["other"]) {
      return data["other"]["sum"];
    } else {
      return 0;
    }
  }
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "70%", margin: "20px" }}>
        {/* <SimpleMap/> */}
        <Map center={[48.85, 2.44]} zoom={3}>
          <LayersControl>
            <LayersControl.BaseLayer name="Base" checked>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
            </LayersControl.BaseLayer>
            <LayersControl.Overlay name="Matrix" checked>
              <Choropleth
                fitBoundsOnLoad
                fitBoundsOnUpdate
                data={data}
                identity={(feature) =>
                  feature.id.toString() + Math.random().toString()
                }
                valueProperty={(feature) => feature.properties.density}
                visible={(feature) => feature.properties.density}
                // colors={gradient}
                scale={[gradient[0], gradient[gradient.length - 1]]}
                steps={500}
                mode="e"
                style={style}
                onEachFeature={(feature, layer) =>
                  layer.bindPopup(
                    "Provisioning : " +
                      feature.properties.density.toString() +
                      " Mbps Lat : " +
                      feature.properties.lat.toString() +
                      " Lon : " +
                      feature.properties.lon.toString()
                  )
                }
                // ref={(el) => this.choropleth = el.leafletElement}
              />
            </LayersControl.Overlay>
            <LayersControl.Overlay name="HeatMap" checked>
              <HeatmapLayer
                fitBoundsOnLoad
                fitBoundsOnUpdate
                points={data["features"] || []}
                longitudeExtractor={(m) => m["properties"]["lon"]}
                latitudeExtractor={(m) => m["properties"]["lat"]}
                intensityExtractor={(m) =>
                  parseFloat(m["properties"]["density"])
                }
                // max = {8}
                // radius = {10}
                // blur = {5}
              />
            </LayersControl.Overlay>
          </LayersControl>
        </Map>
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "30%" }}>
        <img
          src={"/logo-eutelsat.png"}
          className="App-logo"
          alt="logo"
          style={{
            width: "80%",
            height: "auto",
            marginTop: 20,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "700px",
            margin: "20px",
          }}
        >
          <AutocompleteAsync
            value={country}
            setValue={setCountry}
            url="api/aero/airline/country"
            label="Airline country(ies)"
          />
          <AutocompleteAsync
            value={airline}
            setValue={setAirline}
            url="api/aero/airline"
            label="Airline(s)"
          />
          <AutocompleteAsync
            value={aircraftManufacturer}
            setValue={setAircraftManufacturer}
            url="api/aero/aircraft/manufacturer"
            label="Aircraft manufacturer(s)"
          />
          <AutocompleteAsync
            value={aircraftType}
            setValue={setAircraftType}
            url="api/aero/aircraft/type"
            label="Aircraft type(s)"
          />
          <AutocompleteAsync
            value={aircraft}
            setValue={setAircraft}
            url="api/aero/aircraft"
            label="Aircraft model(s)"
          />
          <AutocompleteAsync
            value={provider}
            setValue={setProvider}
            url="api/aero/capacity/provider"
            label="Provider(s)"
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              // disableToolbar
              margin="normal"
              variant="inline"
              id="date-picker-dialog"
              label="Choose a day"
              format="dd/MM/yyyy"
              value={starttime}
              onChange={(date) => {
                setStarttime(date);
                setEndtime(date);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardTimePicker
              // disableToolbar
              margin="normal"
              variant="inline"
              ampm={false}
              id="starttime-picker"
              label="Choose a start time (Paris Zone)"
              value={starttime}
              onChange={(date) => {
                setStarttime(date);
                setEndtime(date);
              }}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
            <KeyboardTimePicker
              // disableToolbar
              margin="normal"
              ampm={false}
              variant="inline"
              id="endtime-picker"
              label="Choose an end time (Paris Zone)"
              value={endtime}
              onChange={(date) => {
                setEndtime(date);
              }}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </MuiPickersUtilsProvider>
          <FormControl component="fieldset">
            <FormLabel component="legend"></FormLabel>
            <RadioGroup
              row
              value={dataRequest}
              onChange={(event) => {
                setDataRequest(event.target.value);
              }}
            >
              <FormControlLabel value="max" control={<Radio />} label="Max" />
              <FormControlLabel
                value="avg"
                control={<Radio />}
                label="Average"
              />
            </RadioGroup>
          </FormControl>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Button variant="contained" onClick={showOnMap}>
              {" "}
              Show on Map
            </Button>
            <p>Total : {get_sum()} Gbps</p>
            <Button variant="contained" onClick={exportCsv} color="primary">
              {" "}
              Export CSV
            </Button>
            <Backdrop className={classes.backdrop} open={openLoadingBar}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AeroScreen;
