import React, { useState, useEffect } from "react";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Map, TileLayer, LayersControl } from "react-leaflet";
import { MdSend } from "react-icons/md";
import AutocompleteAsync from "../../components/AutoCompleteAsync";
import HeatmapLayer from "react-leaflet-heatmap-layer";
import Choropleth from "react-leaflet-choropleth";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { gradient } from "./gradient";
// import heatmapData from "./../../dummy-data/heatmapData.json";

const style = {
  fillColor: "#F28F3B",
  weight: 0.5,
  opacity: 0.5,
  color: "white",
  dashArray: "3",
  fillOpacity: 0.5,
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  toggleText: {
    width: "4em",
    marginLeft: "2em",
  },
  toggleContainer: {
    display: "flex",
    alignSelf: "flex-end",
    marginRight: "3em",
    marginTop: "1em",
  },
  datetimeBloc: {
    display: "inline-flex",
  },
  datetimeLeft: {
    marginRight: "1em",
  },
  posMapInput: {
    marginRight: "1em",
  },
}));

// Proxy to local back-end server
function fetchApi(resource, init) {
  return fetch(`${process.env.REACT_APP_ENDPOINT}/${resource}`, init);
}

function MaritimeScreen() {
  const [fromDate, setFromDate] = useState(new Date("2019-04-22T00:00"));
  const [fromDateError, setFromDateError] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [isValideShowBtn, setIsValideShowBtn] = useState(true);
  const [fromTime, setFromTime] = useState(new Date("2019-04-22T00:00"));
  const [toDate, setToDate] = useState(new Date("2019-04-23T00:00"));
  const [toTime, setToTime] = useState(new Date("2019-04-23T00:00"));
  const [vesselName, setVesselName] = useState([]);
  const [vesselType, setVesselType] = useState([]);
  const [length, setLength] = useState([]);
  const [operator, setOperator] = useState([]);
  const [company, setCompany] = useState([]);
  const [dataRequest, setDataRequest] = React.useState("max");
  const [data, setData] = useState([]);
  const [map, setMap] = useState(null);
  const [zoomInput, setZoomInput] = useState(3);
  const [latInput, setLatInput] = useState(48.85);
  const [longInput, setLongInput] = useState(2.44);

  useEffect(() => {
    if (!fromDateError && !toDateError) {
      setIsValideShowBtn(true);
    } else {
      setIsValideShowBtn(false);
    }
  }, [fromDateError, toDateError]);

  const classes = useStyles();
  const [openLoadingBar, setOpenLoadingBar] = React.useState(false);

  function setTime(date, time) {
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      0
    );
    return newDate;
  }

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  async function showOnMap() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        params: {
          dataRequest: dataRequest,
          vesselName: vesselName,
          vesselType: vesselType,
          length: length,
          operator: operator,
          company: company,
          fromDateTime: setTime(fromDate, fromTime),
          toDateTime: setTime(toDate, toTime),
        },
      }),
    };
    setOpenLoadingBar(!openLoadingBar);
    await fetchApi("api/maritime/capacity", requestOptions)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((err) => {
        setOpenLoadingBar(false);
      });

    setOpenLoadingBar(false);
  }

  async function exportCsv() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        params: {
          dataRequest: dataRequest,
          vesselName: vesselName,
          vesselType: vesselType,
          length: length,
          operator: operator,
          company: company,
        },
      }),
    };
    setOpenLoadingBar(!openLoadingBar);
    await fetchApi("api/maritime/export", requestOptions)
      .then(function (t) {
        return t.blob().then((b) => {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(b);
          a.setAttribute("download", "export.csv");
          a.click();
        });
      })
      .catch((err) => {
        setOpenLoadingBar(false);
      });
    setOpenLoadingBar(false);
  }

  function get_sum() {
    if (data["other"]) {
      return data["other"]["sum"];
    } else {
      return 0;
    }
  }
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "70%", margin: "20px" }}>
        <Map
          center={[48.85, 2.44]}
          zoom={3}
          zoomControl={true}
          whenReady={(event) => setMap(event.target)}
        >
          <LayersControl>
            <LayersControl.BaseLayer name="Base" checked>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
            </LayersControl.BaseLayer>
            <LayersControl.Overlay name="Matrix" checked>
              <Choropleth
                fitBoundsOnLoad
                fitBoundsOnUpdate
                data={data}
                identity={(feature) =>
                  feature.id.toString() + Math.random().toString()
                }
                valueProperty={(feature) => feature.properties.density}
                visible={(feature) => feature.properties.density}
                // colors={gradient}
                scale={[gradient[0], gradient[gradient.length - 1]]}
                steps={500}
                mode="e"
                style={style}
                onEachFeature={(feature, layer) =>
                  layer.bindPopup(
                    "Provisioning : " +
                      feature.properties.density.toString() +
                      " Mbps Lat : " +
                      feature.properties.lat.toString() +
                      " Lon : " +
                      feature.properties.lon.toString()
                  )
                }
                // ref={(el) => this.choropleth = el.leafletElement}
              />
            </LayersControl.Overlay>
            <LayersControl.Overlay name="HeatMap" checked>
              <HeatmapLayer
                fitBoundsOnLoad
                fitBoundsOnUpdate
                points={data["features"] || []}
                longitudeExtractor={(m) => m["properties"]["lon"]}
                latitudeExtractor={(m) => m["properties"]["lat"]}
                intensityExtractor={(m) =>
                  parseFloat(m["properties"]["density"])
                }
                // max = {8}
                // radius = {10}
                // blur = {5}
              />
            </LayersControl.Overlay>
          </LayersControl>
        </Map>
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "30%" }}>
        <img
          src={"/logo-eutelsat.png"}
          className="App-logo"
          alt="logo"
          style={{
            width: "80%",
            height: "auto",
            marginTop: 20,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "700px",
            margin: "20px",
          }}
        >
          <AutocompleteAsync
            value={vesselName}
            setValue={setVesselName}
            url="api/maritime/vessel/name"
            label="Vessel name"
          />
          <AutocompleteAsync
            value={vesselType}
            setValue={setVesselType}
            url="api/maritime/vessel/type"
            label="Vessel type"
          />
          <AutocompleteAsync
            value={length}
            setValue={setLength}
            url="api/maritime/length"
            label="Length"
          />
          <AutocompleteAsync
            value={operator}
            setValue={setOperator}
            url="api/maritime/operator"
            label="Operator"
          />
          <AutocompleteAsync
            value={company}
            setValue={setCompany}
            url="api/maritime/company"
            label="Company"
          />

          <div style={{ display: "flex" }}>
            <TextField
              id="standard-number"
              label="Zoom"
              type="number"
              className={classes.posMapInput}
              value={zoomInput}
              onChange={(event) => {
                setZoomInput(event.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="standard-number"
              label="Lat"
              type="number"
              value={latInput}
              className={classes.posMapInput}
              onChange={(event) => {
                setLatInput(event.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="standard-number"
              label="Long"
              type="number"
              value={longInput}
              className={classes.posMapInput}
              onChange={(event) => {
                setLongInput(event.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Tooltip title="Go">
              <IconButton
                color="primary"
                onClick={() => {
                  map.setView([latInput, longInput], zoomInput);
                }}
                aria-label="delete"
              >
                <MdSend />
              </IconButton>
            </Tooltip>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={classes.datetimeBloc}>
              <div className={classes.datetimeLeft}>
                <KeyboardDatePicker
                  // disableToolbar
                  margin="normal"
                  variant="inline"
                  id="date-picker-dialog"
                  label="From"
                  helperText={fromDateError}
                  error={fromDateError !== ""}
                  format="dd/MM/yyyy"
                  value={fromDate}
                  onChange={(date) => {
                    setFromDateError("");
                    if (!isValidDate(date)) {
                      setFromDateError(`Date is not valide`);
                    }
                    if (date > toDate) {
                      setFromDateError(
                        `From-date cannot be greater than To-date`
                      );
                    }
                    setFromDate(date);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </div>
              <KeyboardTimePicker
                // disableToolbar
                margin="normal"
                variant="inline"
                ampm={false}
                id="starttime-picker"
                label="Time"
                value={fromTime}
                onChange={(date) => {
                  setFromTime(date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </div>
            <div className={classes.datetimeBloc}>
              <div className={classes.datetimeLeft}>
                <KeyboardDatePicker
                  // disableToolbar
                  margin="normal"
                  variant="inline"
                  id="date-picker-dialog"
                  label="To"
                  format="dd/MM/yyyy"
                  helperText={toDateError}
                  error={toDateError !== ""}
                  value={toDate}
                  onChange={(date) => {
                    setToDateError("");
                    if (!isValidDate(date)) {
                      setToDateError(`Date is not valide`);
                    }
                    if (date < fromDate) {
                      setToDateError(`To-date cannot be less than From-date`);
                    }
                    setToDate(date);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </div>
              <KeyboardTimePicker
                // disableToolbar
                margin="normal"
                ampm={false}
                variant="inline"
                id="endtime-picker"
                label="Time"
                value={toTime}
                onChange={(date) => {
                  setToTime(date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
          <FormControl component="fieldset">
            <FormLabel component="legend"></FormLabel>
            <RadioGroup
              row
              value={dataRequest}
              onChange={(event) => {
                setDataRequest(event.target.value);
              }}
            >
              <FormControlLabel value="max" control={<Radio />} label="Max" />
              <FormControlLabel
                value="avg"
                control={<Radio />}
                label="Average"
              />
            </RadioGroup>
          </FormControl>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Button
              variant="contained"
              onClick={showOnMap}
              disabled={!isValideShowBtn}
            >
              Show on Map
            </Button>
            <p>Total : {get_sum()} Gbps</p>
            <Button variant="contained" onClick={exportCsv} color="primary">
              Export CSV
            </Button>
            <Backdrop className={classes.backdrop} open={openLoadingBar}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaritimeScreen;
