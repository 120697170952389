import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toggle from "react-toggle";
import { IoAirplaneSharp, IoBoatSharp } from "react-icons/io5";

import AeroScreen from "./Screens/AeroScreen";
import MaritimeScreen from "./Screens/Maritime/MaritimeScreen";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  toggleText: {
    width: "4em",
    marginLeft: "2em",
  },
  toggleContainer: {
    display: "flex",
    alignSelf: "flex-end",
    marginRight: "3em",
    marginTop: "1em",
  },
}));

function App() {
  const [isAero, setIsAero] = useState(false);

  const classes = useStyles();

  return (
    <div className="App" style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          placeSelf: "flex-end",
          marginRight: "4em",
          marginTop: "1em",
        }}
      >
        <div
          style={{
            display: "inline-flex",
          }}
        >
          <label>
            <Toggle
              defaultChecked={isAero}
              className="space-switcher"
              onChange={() => {
                console.log("toggle called");
                setIsAero(!isAero);
              }}
              icons={{
                checked: <IoAirplaneSharp size={20} />,
                unchecked: <IoBoatSharp size={20} />,
              }}
            />
          </label>
          <span className={classes.toggleText}>
            {isAero ? "Aero" : "Maritime"}
          </span>
        </div>
      </div>
      {isAero ? <AeroScreen /> : <MaritimeScreen />}
      <div style={{ display: "flex" }}></div>
    </div>
  );
}

export default App;
